<template>
  <v-container id="dashboard" fluid tag="section">
    <v-text-field class="mt-4" label="اكتب للبحث" outlined append-icon="mdi-magnify" v-model="search">
    </v-text-field>
  
      <v-data-table :headers="headers" :items="items" sort-by="phone" class="elevation-1">
 <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title> {{ $t("Drawer.callUs") }} </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="890px">

         
            <v-toolbar
              color="primary"
              dark>{{ $t('datatable.message')}}</v-toolbar>
         
            <v-stepper >
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card class="mb-4" flat>
                        <v-container>
                          <v-row>
                            <v-col  class=" text-start  " cols="12" sm="12" md="12">
                              
        <p  >{{ $t('datatable.name')}}: {{itemId.name}} </p>
        <p >{{ $t('datatable.email')}}:<a :href='"mailto:"+itemId.email'> {{itemId.email}} </a>     </p>
        <p >{{ $t('datatable.message')}}: {{itemId.message}} </p> 
        </v-col>
                          </v-row> 
                        </v-container>

                  </v-card>
                  <v-btn  color="primary"  @click="close">
                    {{$t("close")}}
                  </v-btn>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <button class="ml-5" @click="getshow(item.id)" v-if="!item.isDeleted" v-bind="attrs"
                    v-on="on">{{ $t("edit") }}  </button>
          </template>
          <span>{{ $t("edit") }} </span>
        </v-tooltip>
      
      </template>
 
         
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">{{ $t("Reloading") }}</v-btn>
      </template>
    </v-data-table>

  </v-container>


</template>

<script>


import axios from "axios";

export default {

  name: "callus",
  data() {
    return {
     
  
      dialog: false,
       
    
      items: [],
      headers: [
        {text: 'id', value: 'id',}
        ,{
        text: this.$t('datatable.name'),
        align: "start",
        value:"name"
      },
      {
        text: this.$t('datatable.email'),
        align: "start",
        value:"email"
      },
      {
        text: this.$t('datatable.message'),
        align: "start",
        value:"message"
      },
      ,
      {
          text: this.$t('Processes'),
          value: "actions",
          sortable: false
      }
      ],
      itemId:[],
     
    }
  },
  methods: {
   
    close() {
      this.dialog = false;

    },
    getshow($id){
      try {
        axios.get("/callus/" + $id, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorizations: "Bearer " + this.$store.state.AdminInfo.token
          }
        }).then(res => {
          this.itemId = res.data.data;
      this.dialog=true;

        })
            .catch(() => {
            });
      }catch (e) {
      }
    },
    initialize() {
      this.loading = true;
      try {
        axios.get("/callus", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorizations: "Bearer " + this.$store.state.AdminInfo.token
          }
        }).then(res => {
          this.loading = false;
          this.items = res.data.data;

        })
            .catch(() => {
              this.loading = false;
            });
      }catch (e) {
        
      }

    },
},
  components: {

  },
  computed: {
  
},


   created() {
    this.initialize();
    
  }
  }
          
</script>
